var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.show,"title":"Document Expiration Settings","fullscreen":true},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.closeSettings}},[_c('div',{staticClass:"document-users-order"},[_c('el-tabs',{attrs:{"type":"card"}},[_c('el-tab-pane',{attrs:{"label":"Document Validate From Date"}},[_c('el-switch',{staticStyle:{"display":"block"},attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-text":"Custom Selection","inactive-text":"Manual Selection"},model:{value:(_vm.expirationSettings.valid_from_date.is_manual),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_from_date, "is_manual", $$v)},expression:"expirationSettings.valid_from_date.is_manual"}}),(_vm.expirationSettings.valid_from_date.is_manual)?_c('div',[_c('el-radio-group',{model:{value:(_vm.expirationSettings.valid_from_date.manual_type),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_from_date, "manual_type", $$v)},expression:"expirationSettings.valid_from_date.manual_type"}},[_c('el-radio-button',{attrs:{"label":"SIGNAUTRE_BASED"}}),_c('el-radio-button',{attrs:{"label":"ADMIN_APPROVED"}}),_c('el-radio-button',{attrs:{"label":"DOCUMENT_DATE_FIELD_BASED"}})],1),(
              _vm.expirationSettings.valid_from_date.manual_type ==
              'SIGNAUTRE_BASED'
            )?_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('b',[_vm._v(" User Signed ")]),_c('el-select',{model:{value:(_vm.expirationSettings.valid_from_date.signature_user),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_from_date, "signature_user", $$v)},expression:"expirationSettings.valid_from_date.signature_user"}},_vm._l((_vm.documentUsers),function(user,index){return _c('el-option',{key:index,attrs:{"value":user.value}},[_vm._v(" "+_vm._s(user.name)+" ")])}),1)],1),_c('el-col',{attrs:{"span":8}},[_c('p',[_vm._v("No of dates after user Signed")]),_c('el-input',{model:{value:(
                    _vm.expirationSettings.valid_from_date
                      .signature_user_post_days
                  ),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_from_date
                      , "signature_user_post_days", $$v)},expression:"\n                    expirationSettings.valid_from_date\n                      .signature_user_post_days\n                  "}})],1)],1)],1):_vm._e(),(
              _vm.expirationSettings.valid_from_date.manual_type ==
              'ADMIN_APPROVED'
            )?_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('p',[_vm._v("No of days after user ADMIN/HR approved the document")]),_c('el-input',{model:{value:(
                    _vm.expirationSettings.valid_from_date.admin_approve_post_days
                  ),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_from_date, "admin_approve_post_days", $$v)},expression:"\n                    expirationSettings.valid_from_date.admin_approve_post_days\n                  "}})],1)],1)],1):_vm._e(),(
              _vm.expirationSettings.valid_from_date.manual_type ==
              'DOCUMENT_DATE_FIELD_BASED'
            )?_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('b',[_vm._v(" Document Date field ")]),_c('el-select',{model:{value:(
                    _vm.expirationSettings.valid_from_date.document_date_field
                  ),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_from_date, "document_date_field", $$v)},expression:"\n                    expirationSettings.valid_from_date.document_date_field\n                  "}},_vm._l((_vm.documentDateFields()),function(field,index){return _c('el-option',{key:index,attrs:{"value":field.key,"label":field.label}})}),1)],1),_c('el-col',{attrs:{"span":8}},[_c('p',[_vm._v("No of days after user Document date field")]),_c('el-input',{model:{value:(
                    _vm.expirationSettings.valid_from_date.date_field_post_days
                  ),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_from_date, "date_field_post_days", $$v)},expression:"\n                    expirationSettings.valid_from_date.date_field_post_days\n                  "}})],1)],1)],1):_vm._e()],1):_vm._e(),(!_vm.expirationSettings.valid_from_date.is_manual)?_c('div',[_c('b',[_vm._v(" Note: ")]),_c('p',[_vm._v(" Document Validation date should be slected by HR/ADMIN when approve the document ")])]):_vm._e()],1),_c('el-tab-pane',{attrs:{"label":"Document Expiration Date"}},[_c('el-switch',{staticStyle:{"display":"block"},attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-text":"Custom Selection","inactive-text":"Manual Selection"},model:{value:(_vm.expirationSettings.valid_to_date.is_manual),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_to_date, "is_manual", $$v)},expression:"expirationSettings.valid_to_date.is_manual"}}),(_vm.expirationSettings.valid_to_date.is_manual)?_c('div',[_c('el-radio-group',{model:{value:(_vm.expirationSettings.valid_to_date.manual_type),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_to_date, "manual_type", $$v)},expression:"expirationSettings.valid_to_date.manual_type"}},[_c('el-radio-button',{attrs:{"label":"SIGNAUTRE_BASED"}}),_c('el-radio-button',{attrs:{"label":"ADMIN_APPROVED"}}),_c('el-radio-button',{attrs:{"label":"DOCUMENT_DATE_FIELD_BASED"}})],1),(
              _vm.expirationSettings.valid_to_date.manual_type ==
              'SIGNAUTRE_BASED'
            )?_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('b',[_vm._v(" User Signed ")]),_c('el-select',{model:{value:(_vm.expirationSettings.valid_to_date.signature_user),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_to_date, "signature_user", $$v)},expression:"expirationSettings.valid_to_date.signature_user"}},_vm._l((_vm.documentUsers),function(user,index){return _c('el-option',{key:index,attrs:{"value":user.value}},[_vm._v(" "+_vm._s(user.name)+" ")])}),1)],1),_c('el-col',{attrs:{"span":8}},[_c('p',[_vm._v("No of dates after user Signed")]),_c('el-input',{model:{value:(
                    _vm.expirationSettings.valid_to_date.signature_user_post_days
                  ),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_to_date, "signature_user_post_days", $$v)},expression:"\n                    expirationSettings.valid_to_date.signature_user_post_days\n                  "}})],1)],1)],1):_vm._e(),(
              _vm.expirationSettings.valid_to_date.manual_type == 'ADMIN_APPROVED'
            )?_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('p',[_vm._v("No of days after user ADMIN/HR approved the document")]),_c('el-input',{model:{value:(
                    _vm.expirationSettings.valid_to_date.admin_approve_post_days
                  ),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_to_date, "admin_approve_post_days", $$v)},expression:"\n                    expirationSettings.valid_to_date.admin_approve_post_days\n                  "}})],1)],1)],1):_vm._e(),(
              _vm.expirationSettings.valid_to_date.manual_type ==
              'DOCUMENT_DATE_FIELD_BASED'
            )?_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('b',[_vm._v(" Document Date field ")]),_c('el-select',{model:{value:(
                    _vm.expirationSettings.valid_to_date.document_date_field
                  ),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_to_date, "document_date_field", $$v)},expression:"\n                    expirationSettings.valid_to_date.document_date_field\n                  "}},_vm._l((_vm.documentDateFields),function(field,index){return _c('el-option',{key:index,attrs:{"value":field.key,"label":field.label}})}),1)],1),_c('el-col',{attrs:{"span":8}},[_c('p',[_vm._v("No of days after user Document date field")]),_c('el-input',{model:{value:(
                    _vm.expirationSettings.valid_to_date.date_field_post_days
                  ),callback:function ($$v) {_vm.$set(_vm.expirationSettings.valid_to_date, "date_field_post_days", $$v)},expression:"\n                    expirationSettings.valid_to_date.date_field_post_days\n                  "}})],1)],1)],1):_vm._e()],1):_vm._e(),(!_vm.expirationSettings.valid_to_date.is_manual)?_c('div',[_c('b',[_vm._v(" Note: ")]),_c('p',[_vm._v(" Document Validation date should be slected by HR/ADMIN when approve the document ")])]):_vm._e()],1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeSettings}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveConfig}},[_vm._v("Save Configuration")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }