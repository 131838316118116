<template>
	<section class="configure-company-document" id="drag-template-background">
		<div class="inner-navbar">
			<el-row
				type="flex"
				align="middle"
				justify="center"
				v-loading.fullscreen="loading"
				element-loading-spinner="el-icon-loading"
				element-loading-text="Fetching Data..."
			>
				<el-col :span="5">
					<el-button
						round
						icon="el-icon-back"
						@click="backToDashCompanyDocuments"
						>Back to Dashboard</el-button
					>
				</el-col>
				<el-col :span="18">
					<el-row type="flex" align="middle">
						<el-col :span="16">
							<div class="left-block vue-form">
								<el-form class="form-title">
									<el-input
										v-model="documentName"
										placeholder="Enter Document Title"
									></el-input>
									<p
										class="error"
										v-if="
											getConfigurableDocumentErrors &&
											getConfigurableDocumentErrors.name
										"
									>
										{{ getConfigurableDocumentErrors.name }}
									</p>
								</el-form>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="right-block float-right">
								<el-button
									class="btn preview-button"
									@click="openDocumentUserSettings()"
									icon="el-icon-setting"
									>Settings</el-button
								>
								<el-button
									class="btn preview-button"
									v-if="configurableDocumentId"
									@click="openDocumentExpirationSettings()"
									icon="el-icon-setting"
									>Expiration Settings</el-button
								>
								<el-button
									class="btn"
									type="success"
									@click="saveConfigurableDocument()"
									>SAVE</el-button
								>
							</div>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</div>

		<div class="input-options">
			<el-row type="flex" justify="center">
				<el-col :span="24">
					<!-- <el-col :span="16" style="width:640px;"> -->
					<div class="container" v-if="!loading">
						<ckeditor
							v-loading="documentDataLoading"
							element-loading-text="Preparing Editor..."
							ref="editor"
							v-model="editorData"
							:config="editorConfig"
							@ready="onEditorReady"
						></ckeditor>
					</div>
				</el-col>
			</el-row>
		</div>

		<ConfigureDocumentUsers
			v-if="showDocumentUserSettings"
			:documentUsers="documentUsers"
			:enforceSignatureOrder="enforceSignatureOrder"
			:isSubmit="submitEvent"
			:allElements="allElements"
			v-on:close="closeDocumentUserSettings"
		></ConfigureDocumentUsers>
		<ConfigureDocumentExpirationDates
			v-if="showDocumentExpirationDatesSettings"
			:isSubmit="submitEvent"
			:expirationSettings="expirationSettings"
			:allElements="fields"
			:documentUsers="documentUsers"
			:configurableDocumentId="configurableDocumentId"
			v-on:close="closeDocumentExpireDateSettings"
		></ConfigureDocumentExpirationDates>
	</section>
</template>


<script>
	import appConfig from "@/config/app";
	import { mapGetters } from "vuex";
	import documentEditorHelper from "@/utils/documentEditorHelper";
	import ConfigureDocumentUsers from "./ConfigureDocumentUsers";
	import ConfigureDocumentExpirationDates from "./ConfigureDocumentExpirationDates";
	// import ConfigureSingleDocumentUsers from "./ConfigureSingleDocumentUsers";

	export default {
		name:"configureDocuments-ConfigureDocTypeDocument",
		components: {
			ConfigureDocumentUsers,
			ConfigureDocumentExpirationDates,
			// ConfigureSingleDocumentUsers
		},

		created() {},
		data() {
			return {
				showSendDocumentoUserSettings: false,
				documentName: "",
				loading: true,
				documentDataLoading: false,
				templateUsed: [],
				templateVariables: [],
				templates: [],
				fields: [],
				editorData: "",
				editorConfig: {},
				configurableDocumentId: null,
				authorizedSignatures: [],
				documentFormData: {
					document_body: "",
				},
				enforceSignatureOrder: false,
				signaturesFields: [],
				signatureDates: [],
				signaturesConfig: [],
				submitEvent: false,
				params: {},

				showDocumentUserSettings: false,
				documentUsers: [
					{
						default: true,
						name: "SENDER",
						value: "SENDER",
						type: "SENDER",
					},
					{
						default: true,
						name: "RECEIVER",
						value: "RECEIVER",
						type: "RECEIVER",
					},
				],
				allElements: [],
				showDocumentExpirationDatesSettings: false,
				expirationSettings: {
					valid_from_date: {
						is_manual: false,
						manual_type: "",
						signature_user: "",
						signature_user_post_days: "",
						admin_approve_post_days: "",
					},
					valid_to_date: {
						is_manual: false,
						manual_type: "",
						signature_user: "",
						signature_user_post_days: "",
						admin_approve_post_days: "",
					},
				},
			};
		},
		computed: {
			...mapGetters("company", [
				"getCompanyProfileData",
				"getAuthorizedSignatures",
				"getAuthorizedSignatureById",
			]),
			...mapGetters("auth", ["getAuthenticationDetails"]),
			...mapGetters("companyDocuments", [
				"getCompanyDocumentDataById",
			]),
			...mapGetters("configurableDocuments", [
				"getConfigurableDocumentUpdateStatus",
				"getConfigurableDocumentAddStatus",
				"getNewConfigurableDocumentDataId",
				"getConfigurableDocumentData",
				"getConfigurableDocumentErrors",
				"getConfigurableDocumentDataById",
			]),

			...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),

			getLogoUrl() {
				return appConfig.S3_BUCKET_URL + "/" + this.getCompanyProfileData.logo;
			},
			getBackgroundImage() {
				return (path) => appConfig.S3_BUCKET_URL + "/" + path;
			},
			getAccessToken() {
				return this.getAuthenticationDetails.access_token;
			},
			getImageUploadPath() {
				return appConfig.API_URL + "/document-assets";
			},
		},

		async mounted() {
			this.documentDataLoading = true;
			await this.fetchAuthorizedSignatures();
			await this.getTemplateVariables();

			await this.fetchCompanyDocument();
			await this.setMentions();
			this.loading = false;

			if (!this.configurableDocumentId) {
				this.openDocumentUserSettings();
			}

			if (this.$route.query.openSendDocumentsModal) {
				this.showSendDocumentoUserSettings = true;
			}
			this.documentDataLoading = false;
		},
		methods: {
			async fetchCompanyDetails() {
				await this.$store.dispatch("company/getCompany");
			},
			async fetchCompanyDocument() {
				this.documentId = this.$route.params.document_id;
				this.configurableDocumentId = this.$route.params.configurable_document_id;
				if (this.configurableDocumentId) {
					await this.fetchDocumentData();
				} else {
					let params = {
						id: this.documentId,
					};
					await this.$store.dispatch(
						"companyDocuments/fetchCompanyDocumentById",
						params
					);
					this.documentName = this.getCompanyDocumentDataById.title;
					this.editorConfig.documentUsers = this.documentUsers;
				}

				await this.$store.dispatch("configurableDocuments/clearErrors");
			},
			async fetchDocumentData() {
				this.documentDataLoading = true;
				await this.$store.dispatch(
					"configurableDocuments/fetchConfigurableDocumentById",
					{
						id: this.configurableDocumentId,
					}
				);

				this.documentFormData = JSON.parse(
					JSON.stringify(this.getConfigurableDocumentDataById)
				);

				this.documentName = this.documentFormData.name;
				this.editorData = this.documentFormData.document_body;
				this.enforceSignatureOrder = this.documentFormData.enforce_signature_order;
				this.documentUsers = this.documentFormData.document_users;
				if (this.documentFormData.document_expiration_settings) {
					this.expirationSettings = this.documentFormData.document_expiration_settings;
				}
			},

			backToDashCompanyDocuments() {
				this.$router.push({ path: "/company-documents/custom" });
			},

			setMentions() {
				this.editorConfig = documentEditorHelper.editorConfig;
				this.editorConfig.documentUsers = this.documentUsers;

				this.editorConfig.mentions = [
					{
						feed: this.dataFeed(),
						itemTemplate:
							'<li data-id="{id}">' +
							"<div><i>{field_name}</i></div>" +
							'<div><strong class="template-name">{template_name}</strong></div>' +
							"</li>",
						outputTemplate:
							"<span>" +
							"<b style='background:#efefef' field_type={field_type} template_id='{template_id}' template_field_id='{template_field_id}'  data-mention-id='{template_field_key}' title='{template_name}'>@{field_name}</b><span>&nbsp;</span>" +
							"</span>",
						minChars: 0,
					},
				];

				this.editorConfig.filebrowserUploadUrl = this.getImageUploadPath;
				this.editorConfig.filebrowserUploadMethod = "xhr";
				this.editorConfig.fileTools_requestHeaders = {
					"X-Requested-With": "XMLHttpRequest",
					Authorization: this.getAccessToken,
				};
			},
			saveDocument() {
				console.log(this.editorData);
			},
			updateDocumentUsers() {
				this.loading = true;

				this.editorConfig.documentUsers = this.documentUsers;
				setTimeout(() => {
					this.loading = false;
				});
			},
			async getTemplateVariables() {
				await this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
					get_all: true,
				});

				let allTemplates = this.getAllCompanyTemplatesData
					? this.getAllCompanyTemplatesData.data
					: [];

				allTemplates.forEach((template) => {
					let fields = template.sections ? template.sections[0].fields : [];

					fields.forEach((field) => {
						if (field.input_type != "HEADING") {
							let variable = {
								id: this.templateVariables.length + 1,
								template_id: template._id,
								template_field_id: field._id,
								template_field_key:
									template.name.split(" ").join("") + "#" + field.key,
								template_name: template.name,
								field_name: field.label,
								field_type: "TEMPLATE_FIELD",
							};
							this.templateVariables.push(variable);
						}
					});
				});

				this.templateVariables.push({
					id: this.templateVariables.length + 1,
					template_id: "CompanyDetails",
					template_field_id: "company_name",
					template_field_key: "CompanyDetails#name",
					template_name: "Compnay Details",
					field_name: "Company Name",
					field_type: "COMPANY_ASSET",
				});

				this.templateVariables.push({
					id: this.templateVariables.length + 1,
					template_id: "CompanyDetails",
					template_field_id: "company_logo",
					template_field_key: "CompanyDetails#logo",
					template_name: "Compnay Details",
					field_name: "Company Logo",
					field_type: "COMPANY_ASSET",
				});

				this.getAuthorizedSignaturesAsMentions();
			},

			onEditorReady() {
				this.documentDataLoading = true;
				setTimeout(() => {
					this.documentDataLoading = false;
				}, 1000);
			},
			dataFeed() {
				return (opts, callback) => {
					var matchProperty = "field_name",
						data = this.templateVariables.filter(function (item) {
							if (item[matchProperty]) {
								return (
									item[matchProperty]
										.toLowerCase()
										.indexOf(opts.query.toLowerCase()) > -1
								);
							}
						});

					data = data.sort(function (a, b) {
						return a[matchProperty].localeCompare(b[matchProperty], undefined, {
							sensitivity: "accent",
						});
					});
					callback(data);
				};
			},

			getAuthorizedSignaturesAsMentions() {
				this.authorizedSignatures.forEach((signature) => {
					this.templateVariables.push({
						id: this.templateVariables.length + 1,
						template_id: "AuthorizedSignature",
						template_field_id: "company_logo",
						template_field_key: "AuthorizedSignature#id_" + signature._id,
						field_name: `${signature.designation} Authorized Signature`,
						template_name: "Authorized Signatures",
						field_type: "COMPANY_ASSET",
					});
				});
			},

			async fetchAuthorizedSignatures() {
				await this.$store.dispatch("company/getCompanyAuthorizationSignatures");
				this.authorizedSignatures = this.getAuthorizedSignatures;
			},

			prepareElements() {
				let fields = [];

				let templateIds = [];
				var el = document.createElement("html");
				el.innerHTML =
					"<html><head></head><body>" + this.editorData + "</body></html>";

				const mentions = el.querySelectorAll("[data-mention-id]");
				for (let index = 0; index < mentions.length; index++) {
					// replacing employee details
					let templateId = mentions[index].getAttribute("template_id");
					let templateFieldId = mentions[index].getAttribute("template_field_id");
					let key = mentions[index].getAttribute("data-mention-id");
					let fieldType = mentions[index].getAttribute("field_type");
					let filledBy = mentions[index].getAttribute("filled-by");

					if (key && fieldType != "COMPANY_ASSET") {
						if (templateId) {
							templateIds.push(templateId);
						}
						fields.push({
							key: key,
							template_id: templateId,
							template_field_id: templateFieldId,
							field_type: fieldType,
							filled_by: filledBy,
						});
					}

					this.templateUsed = Array.from(new Set(templateIds));

					this.fields = fields;
				}

				let formElements = this.prepareFormElements(el);

				return formElements;
			},
			prepareFormElements(el) {
				let duplicateKeyCounter = {};
				el = this.prepareInputFields(el, duplicateKeyCounter);
				this.prepareSignatureFields(el, duplicateKeyCounter);
				return this.elements;
			},

			generateKeyForField(label) {
				let parsedLabel = label.toLowerCase().replace(/[^a-zA-Z 0-9]/g, "");
				parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");

				return parsedLabel;
			},

			prepareInputFields(el, duplicateKeyCounter) {
				const inputs = el.querySelectorAll("input");
				for (let index = 0; index < inputs.length; index++) {
					let label = inputs[index].getAttribute("name");
					let filledBy = inputs[index].getAttribute("filled-by");
					let fieldType = inputs[index].getAttribute("field-type");

					let field = {
						value: "",
						label,
						filled_by: filledBy,
						type: fieldType,
					};

					let newKey = this.generateKeyForField(label);
					if (duplicateKeyCounter[newKey]) {
						field.key = newKey + "_" + duplicateKeyCounter[newKey];
						duplicateKeyCounter[newKey]++;
					} else {
						duplicateKeyCounter[newKey] = 1;
						field.key = newKey;
					}
					this.fields.push(field);
				}

				const textAreas = el.querySelectorAll("textarea");

				for (let index = 0; index < textAreas.length; index++) {
					let label = textAreas[index].getAttribute("name");
					let filledBy = inputs[index].getAttribute("filled_by");

					let field = {
						value: "",
						label,
						filled_by: filledBy,
					};
					let newKey = this.generateKeyForField(label);
					if (duplicateKeyCounter[newKey]) {
						field.key = newKey + "_" + duplicateKeyCounter[newKey];
						duplicateKeyCounter[newKey]++;
					} else {
						duplicateKeyCounter[newKey] = 1;
						field.key = newKey;
					}
					this.fields.push(field);
				}
				return el;
			},

			prepareSignatureFields(el, duplicateKeyCounter) {
				var signatures = el.querySelectorAll("[data-type]");
				for (let index = 0; index < signatures.length; index++) {
					let label = signatures[index].getAttribute("name");
					let filledBy = signatures[index].getAttribute("filled-by");

					let field = {
						value: "",
						type: "SIGNATURE",
						label,
						filled_by: filledBy,
					};

					let newKey = this.generateKeyForField(label);
					if (duplicateKeyCounter[newKey]) {
						field.key = newKey + "_" + duplicateKeyCounter[newKey];
						duplicateKeyCounter[newKey]++;
					} else {
						duplicateKeyCounter[newKey] = 1;
						field.key = newKey;
					}
					this.fields.push(field);
				}
				return el;
			},

			async saveConfigurableDocument() {
				try {
					if (this.validateForm()) {
						let params = this.prepareFields();
						if (params.id) {
							await this.updateForm(params);
						} else {
							await this.addNewForm(params);
						}
					} else {
						this.showErrorElementDialog = true;
					}
				} catch (err) {
					console.log(err);
					this.$notify.error({
						title: "Error",
						message: "Error at saving form",
					});
				}
			},
			validateForm() {
				if (!this.editorData || this.editorData == "") {
					this.$notify.warning({
						title: "Warning",
						message: "Please configure your document",
					});
					return false;
				}
				return true;
			},
			async addNewForm(params) {
				await this.$store.dispatch(
					"configurableDocuments/addConfigurableDocument",
					params
				);
				if (this.getConfigurableDocumentAddStatus) {
					this.$notify.success({
						title: "Success",
						message: "Form saved successfully",
					});

					if (this.getNewConfigurableDocumentDataId) {
						this.$router.push({
							name: "edit-configure-custom-document",
							params: {
								document_id: this.documentId,
								configurable_document_id: this.getNewConfigurableDocumentDataId,
							},
						});
					}
				} else {
					this.$notify.error({
						title: "Error",
						message: "Error at saving form",
					});
				}
			},

			async updateForm(params) {
				await this.$store.dispatch(
					"configurableDocuments/updateConfigurableDocument",
					params
				);
				if (this.getConfigurableDocumentUpdateStatus) {
					this.$notify.success({
						title: "Success",
						message: "Form saved successfully",
					});
				} else {
					this.$notify.error({
						title: "Error",
						message: "Error at saving form",
					});
				}
			},

			openDocumentUserSettings(submit = false) {
				this.showDocumentUserSettings = true;

				this.submitEvent = submit;
			},

			openDocumentExpirationSettings(submit = false) {
				this.showDocumentExpirationDatesSettings = true;
				this.prepareElements();
				this.submitEvent = submit;
			},

			closeDocumentUserSettings({
				users,
				enforceSignatureOrder,
				signaturesConfig,
				submit,
			}) {
				if (users && users.length) {
					let prevUsers = this.documentUsers.length;
					let udpatedUsers = users.length;

					this.documentUsers = users;

					if (prevUsers != udpatedUsers) {
						this.updateDocumentUsers();
					}
				}
				if (signaturesConfig && signaturesConfig.length) {
					this.signaturesConfig = signaturesConfig;
				}
				this.submitEvent = false;
				this.showDocumentUserSettings = false;
				this.enforceSignatureOrder = enforceSignatureOrder;
				if (submit) {
					this.saveUpdateData();
				}
			},

			async closeDocumentExpireDateSettings({ expirationSettings }) {
				this.showDocumentExpirationDatesSettings = false;

				if (expirationSettings) {
					this.expirationSettings = expirationSettings;
					await this.saveConfigurableDocument();
				}
			},

			prepareFields() {
				this.prepareElements();

				let data = {
					id: this.configurableDocumentId
						? this.configurableDocumentId
						: undefined,
					name: this.documentName,
					type: "CUSTOM",
					document_users: this.documentUsers,
					document_expiration_settings: this.expirationSettings,
					enforce_signature_order: this.enforceSignatureOrder || false,
					company_document_id: this.$route.params.document_id,
					document_body: this.editorData,
					configure_type: "WORD_DOCUMENT",
					fields: this.fields,
					templates_used: this.templateUsed,
				};

				if (
					this.getCompanyDocumentDataById.description &&
					this.getCompanyDocumentDataById.description.length
				) {
					data.description = this.getCompanyDocumentDataById.description;
				}
				return data;
			},
		},

		watch: {
			async "$route.params.configurable_document_id"() {
				window.location.reload();
			},
		},
		beforeDestroy() {
    this.$store.commit("company/setCompanyProfileData",null,{root:true})
	this.$store.commit("company/setAuthorizedSignatures",null,{root:true})
	this.$store.commit("company/setAuthorizedSignatureById",null,{root:true})
	this.$store.commit("configurableDocuments/setConfigurableDocumentUpdateStatus",null,{root:true})
	this.$store.commit("configurableDocuments/setConfigurableDocumentAddStatus",null,{root:true})
	this.$store.commit("configurableDocuments/setNewConfigurableDocumentDataId",null,{root:true})
	this.$store.commit("configurableDocuments/setConfigurableDocumentData",null,{root:true})
	this.$store.commit("configurableDocuments/setConfigurableDocumentErrors",null,{root:true})
	this.$store.commit("configurableDocuments/setConfigurableDocumentDataById",null,{root:true})
	this.$store.commit("companyDocuments/setCompanyDocumentDataById",null,{root:true})
	this.$store.commit("companyTemplates/setAllCompanyTemplatesData",null,
     {root: true,}
    );
  },
	};
</script>

<style lang="scss" scoped>
	.container {
		padding: 30px;
		display: flex;
		align-items: center;
		text-align: center;
	}

	.inner-container {
		margin: 0 auto;
	}

	.form-builder {
		width: 950px;
		max-height: calc(100vh - 110px);
		overflow-y: auto;
		background-color: #ffffff;
		border: none;
		-webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
		box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
		.form-image-holder {
			width: 100%;
			img {
				width: 100%;
			}
		}

		.form-fields-holder {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			.each-element img {
				width: 100%;
			}
		}
	}
	#drag-template-background {
		.img-fluid {
			height: auto !important;
		}
		.inner-navbar {
			margin-bottom: 1.5em;
			.left-block {
				.el-input__inner {
					min-width: 250px;
					max-width: 300px;
					background: #fff;
					height: 47px;
				}
			}
		}
		.input-options {
			td {
				padding-top: 0;
				vertical-align: top;
				&:nth-child(1) {
					width: 20%;
				}
				&:nth-child(2) {
					width: 80%;
				}
			}
			.inputs-list {
				background: #fff;
				padding: 15px;
				box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.06),
					0 1px 0px rgba(0, 0, 0, 0.08);
				border-radius: 5px;
				.el-button {
					border: none;
					background: rgba(236, 245, 255, 0.7);
					&:hover {
						background: rgba(236, 245, 255, 1);
					}
				}
				li {
					&:not(:last-child) {
						margin-bottom: 15px;
					}
				}
			}
		}
		.form-image {
			max-width: 770px;
			min-height: 1000px;
			background-color: #e6dcdc;
		}
		.el-input__inner {
			display: inline !important;
		}

		.form-holder {
			width: 100%;
			position: relative;
			.el-input__inner,
			.el-textarea__inner {
				height: 100% !important;
				min-height: 30px !important;
				line-height: inherit !important;
			}
			input[type="checkbox"] {
				margin: 0 !important;
			}

			// .draggable.resizable {
			//   position: absolute;
			//   z-index: 100;
			// }
		}

		.sample-form1 {
			position: relative;
			width: 100%;
			max-height: 91vh;
			overflow: scroll;
			border: 1px solid #ccc;
			margin-left: 20px;
			margin-bottom: 45px;

			///
			.each-page {
				position: relative;
				width: 900px;
				display: block;
				height: 800px;
				overflow-y: auto;
				.form-image {
					height: 800px;
					position: absolute;
					width: 850px;
					top: 0;
					left: 0;
				}
				.form-fields {
					height: 800px;
					position: absolute;
					width: 850px;
					top: 0;
					left: 0;
					z-index: 100;
				}
				.page-image {
					width: 850px;
					margin: 0 auto;
				}
			}

			// .draggable.resizable {
			//   position: absolute;
			//   z-index: 100;
			// }
		}

		.color-btn {
			height: 20px;
			width: 20px;
			border-radius: 50%;
			margin: 10px;
			&.red {
				background-color: red;
			}
			&.black {
				background-color: black;
			}

			&.green {
				background-color: green;
			}
		}

		// Fields
		.scrollable-container {
			.field-element {
				position: relative;
				padding: 0.35em 1em;
				width: 100%;
				display: flex;
				margin: 0 0 6px;
				font-size: 0.9em;
				white-space: normal;
				line-height: 1;
				border: 1px solid #dcdfe6;
				background-color: #ffffff;
				color: #606266;
				border-radius: 3px;
				align-items: center;
				&:focus,
				&:hover {
					color: #405bd0;
					border-color: #c6e2ff;
					background-color: #e7ebfb;
				}
				img {
					max-height: 30px;
					padding-right: 10px;
				}
				.name {
					margin-left: 10px;
					i {
						width: 100%;
						font-size: 1em;
						padding-bottom: 0.5em;
					}
				}
			}
		}
		// .draggable {
		//   height: auto !important;
		//   background: rgba(236, 245, 255, 0.48);
		// }
	}
</style>
