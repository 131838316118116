<template>
  <div>
    <section
      class="add-contact-type mt-5"
      v-loading="modalLoading"
      element-loading-text="Creating contact type..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <span v-if="selectedContactType">Update Contact Type</span>
          <span class="fw-normal text-dark" v-else>Add Contact Type</span>
        </div>
        <el-form>
          <el-form-item>
            <el-input
              v-model="contact_type"
              placeholder="Enter contact type"
            ></el-input>
            <p
              class="error"
              v-if="getContactTypeErrors && getContactTypeErrors.name"
            >
              {{ getContactTypeErrors.name }}
            </p>
          </el-form-item>
          <p
            class="error"
            v-if="getContactTypeErrors && getContactTypeErrors.critical_error"
          >
            {{ getContactTypeErrors.critical_error }}
          </p>
          <el-button
            type="danger"
            size="mini"
            @click="updateContactType"
            :disabled="!contact_type.trim()"
            v-if="selectedContactType"
            >Update</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="addContactType"
            :disabled="!contact_type.trim()"
            v-else
            >Submit</el-button
          >&nbsp;&nbsp;

          <el-button @click="cancel" size="mini">Cancel</el-button>
        </el-form>
      </el-card>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "contacts-AddContactTypes",
  props: ["selectedContactType"],
  data() {
    return {
      modalLoading: false,
      contactTypeDialogVisibible: true,
      contact_type: "",
    };
  },
  computed: {
    ...mapGetters("contactTypes", [
      "getContactTypeCreateStatus",
      "getContactTypeErrors",
      "getContactTypeUpdateStatus",
    ]),
  },
  methods: {
    async addContactType() {
      try {
        this.modalLoading = true;
        await this.$store.dispatch("contactTypes/createContactType", {
          name: this.contact_type.trim(),
          entity_type: "INDIVIDUAL",
        });
        if (this.getContactTypeCreateStatus) {
          //this.contact_type = "";
          this.successNotify("Contact type created Successfully");
          this.$emit("newContactTypeAdded", true);
        }
        this.modalLoading = false;
        this.contact_type = "";
      } catch (err) {
        this.modalLoading = false;
        console.log(err);
      }
      this.contact_type = "";
    },
    async updateContactType() {
      try {
        this.modalLoading = true;
        await this.$store.dispatch("contactTypes/update", {
          id: this.selectedContactType._id,
          name: this.contact_type.trim(),
        });
        if (this.getContactTypeUpdateStatus) {
          this.contact_type = "";
          this.successNotify("Contact type updated successfully");
          this.$emit("newContactTypeAdded", true);
        }
        this.$emit('update-success');
        this.modalLoading = false;
      } catch (err) {
        this.modalLoading = false;
        console.log(err);
      }
    },
    setContactType() {
      this.contact_type = this.selectedContactType.name;
    },
    //alert at cancel contact type
    cancel() {
      this.$confirm("Are you sure you want to cancel?")
        .then(() => {
          this.close();
        })
        .catch(() => {});
    },
    close() {
      this.contact_type = "";
      if (this.selectedContactType) {
        this.$emit("clearSelectedContactType");
      }
      this.$router.push({
        path: "/contacts",
      });
    },
  },
  watch: {
    selectedContactType: function (contactType) {
      if (contactType) {
        this.setContactType();
      }
    },
  },
  // beforeDestroy() {
  //   this.$store.commit(
  //   "contactTypes/setContactTypeUpdateStatus", null,{root:true})
  //   this.$store.commit("contactTypes/setContactTypeErrors", null,{root:true})
  //   this.$store.commit("contactTypes/setContactTypeCreateStatus", null,
  //    {
  //     root: true,
  //   }
  //   );
  // },
};
</script>