<template>
  <el-dialog
    :visible.sync="show"
    title="Document Expiration Settings"
    @close="closeSettings"
    :fullscreen="true"
  >
    <div class="document-users-order">
      <el-tabs type="card">
        <el-tab-pane label="Document Validate From Date">
          <el-switch
            style="display: block"
            v-model="expirationSettings.valid_from_date.is_manual"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="Custom Selection"
            inactive-text="Manual Selection"
          >
          </el-switch>

          <div v-if="expirationSettings.valid_from_date.is_manual">
            <el-radio-group
              v-model="expirationSettings.valid_from_date.manual_type"
            >
              <el-radio-button label="SIGNAUTRE_BASED"> </el-radio-button>
              <el-radio-button label="ADMIN_APPROVED"> </el-radio-button>
              <el-radio-button label="DOCUMENT_DATE_FIELD_BASED">
              </el-radio-button>
            </el-radio-group>
            <div
              v-if="
                expirationSettings.valid_from_date.manual_type ==
                'SIGNAUTRE_BASED'
              "
            >
              <el-row>
                <el-col :span="8">
                  <b> User Signed </b>
                  <el-select
                    v-model="expirationSettings.valid_from_date.signature_user"
                  >
                    <el-option
                      v-for="(user, index) of documentUsers"
                      :key="index"
                      :value="user.value"
                    >
                      {{ user.name }}
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <p>No of dates after user Signed</p>
                  <el-input
                    v-model="
                      expirationSettings.valid_from_date
                        .signature_user_post_days
                    "
                  ></el-input>
                </el-col>
              </el-row>
            </div>

            <div
              v-if="
                expirationSettings.valid_from_date.manual_type ==
                'ADMIN_APPROVED'
              "
            >
              <el-row>
                <el-col :span="8">
                  <p>No of days after user ADMIN/HR approved the document</p>
                  <el-input
                    v-model="
                      expirationSettings.valid_from_date.admin_approve_post_days
                    "
                  ></el-input>
                </el-col>
              </el-row>
            </div>

            <div
              v-if="
                expirationSettings.valid_from_date.manual_type ==
                'DOCUMENT_DATE_FIELD_BASED'
              "
            >
              <el-row>
                <el-col :span="8">
                  <b> Document Date field </b>
                  <el-select
                    v-model="
                      expirationSettings.valid_from_date.document_date_field
                    "
                  >
                    <el-option
                      v-for="(field, index) of documentDateFields()"
                      :key="index"
                      :value="field.key"
                      :label="field.label"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <p>No of days after user Document date field</p>
                  <el-input
                    v-model="
                      expirationSettings.valid_from_date.date_field_post_days
                    "
                  ></el-input>
                </el-col>
              </el-row>
            </div>
          </div>

          <div v-if="!expirationSettings.valid_from_date.is_manual">
            <b> Note: </b>
            <p>
              Document Validation date should be slected by HR/ADMIN when
              approve the document
            </p>
          </div>
        </el-tab-pane>

        <el-tab-pane label="Document Expiration Date">
          <el-switch
            style="display: block"
            v-model="expirationSettings.valid_to_date.is_manual"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="Custom Selection"
            inactive-text="Manual Selection"
          >
          </el-switch>

          <div v-if="expirationSettings.valid_to_date.is_manual">
            <el-radio-group
              v-model="expirationSettings.valid_to_date.manual_type"
            >
              <el-radio-button label="SIGNAUTRE_BASED"> </el-radio-button>
              <el-radio-button label="ADMIN_APPROVED"> </el-radio-button>
              <el-radio-button label="DOCUMENT_DATE_FIELD_BASED">
              </el-radio-button>
            </el-radio-group>

            <div
              v-if="
                expirationSettings.valid_to_date.manual_type ==
                'SIGNAUTRE_BASED'
              "
            >
              <el-row>
                <el-col :span="8">
                  <b> User Signed </b>
                  <el-select
                    v-model="expirationSettings.valid_to_date.signature_user"
                  >
                    <el-option
                      v-for="(user, index) of documentUsers"
                      :key="index"
                      :value="user.value"
                    >
                      {{ user.name }}
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <p>No of dates after user Signed</p>
                  <el-input
                    v-model="
                      expirationSettings.valid_to_date.signature_user_post_days
                    "
                  ></el-input>
                </el-col>
              </el-row>
            </div>

            <div
              v-if="
                expirationSettings.valid_to_date.manual_type == 'ADMIN_APPROVED'
              "
            >
              <el-row>
                <el-col :span="8">
                  <p>No of days after user ADMIN/HR approved the document</p>
                  <el-input
                    v-model="
                      expirationSettings.valid_to_date.admin_approve_post_days
                    "
                  ></el-input>
                </el-col>
              </el-row>
            </div>

            <div
              v-if="
                expirationSettings.valid_to_date.manual_type ==
                'DOCUMENT_DATE_FIELD_BASED'
              "
            >
              <el-row>
                <el-col :span="8">
                  <b> Document Date field </b>
                  <el-select
                    v-model="
                      expirationSettings.valid_to_date.document_date_field
                    "
                  >
                    <el-option
                      v-for="(field, index) of documentDateFields"
                      :key="index"
                      :value="field.key"
                      :label="field.label"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <p>No of days after user Document date field</p>
                  <el-input
                    v-model="
                      expirationSettings.valid_to_date.date_field_post_days
                    "
                  ></el-input>
                </el-col>
              </el-row>
            </div>
          </div>

          <div v-if="!expirationSettings.valid_to_date.is_manual">
            <b> Note: </b>
            <p>
              Document Validation date should be slected by HR/ADMIN when
              approve the document
            </p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="closeSettings">Cancel</el-button>
      <el-button type="primary" @click="saveConfig"
        >Save Configuration</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  name:"configureDocuments-ConfigureDocumentExpirationDates",
  props: [
    "documentUsers",
    "allElements",
    "configurableDocumentId",
    "isSubmit",
    "expirationSettings",
  ],
  components: {},
  data() {
    return {
      show: true,
    };
  },

  computed: {},

  mounted() {},
  methods: {
    closeSettings() {
      this.$emit("close", {});
    },
    saveConfig() {
      this.$emit("close", {
        expirationSettings: this.expirationSettings,
        submit: this.isSubmit,
      });
    },

    documentDateFields() {
      return this.allElements.filter((e) => e.type === "DATE");
    },
  },
};
</script>